<template>
  <div class="Recommend">
    <el-row type="flex" justify="center">
      <el-col :xs="22" :sm="22" :md="20" :lg="23" :xl="23">
        <div class="Recommend_pc" v-if="this.equipment == 'pc' && liist.length">
          <Swiper :options="swiperOption">
            <SwiperSlide v-for="item in liist" :key="item.index">
              <div class="card" @click="shipx(item)">
                <div class="coverWrap">
                  <img v-lazy="item.pub_cover" alt="" class="cover" />
                  <img src="@/assets/image/zixun/播放.png" class="pause" alt="" />
                </div>
                <div class="card_content">
                  <p class="title" v-html="item.SYS_TOPIC"></p>
                  <p class="time">{{ $ModuleDate(item.RECORD_TIME) }}</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div class="Recommend_phone" v-if="this.equipment == 'phone' ? true : false">
          <Swiper :options="swiperOption_phone">
            <SwiperSlide v-for="item in liist" :key="item.index">
              <div class="card" @click="shipx(item)">
                <div class="coverWrap">
                  <img v-lazy="item.pub_cover" alt="" class="cover" />
                  <img src="@/assets/image/zixun/播放.png" alt="" class="pause" />
                </div>
                <div class="card_content">
                  <p class="title" v-html="item.SYS_TOPIC"></p>
                  <p class="time">{{ $ModuleDate(item.RECORD_TIME) }}</p>
                </div>
              </div>
            </SwiperSlide>
            <div class="swiper-pagination" slot="pagination"></div>
          </Swiper>

          <div class="arrows" v-if="jianshow">
            <img src="@/assets/image/home/图层 16.png" alt="" class="rePhone-button-prev" />
            <img src="@/assets/image/home/图层 16.png" alt="" class="rePhone-button-next"
              style="transform: rotateZ(180deg)" />
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 相关视频内容 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import api from "@/api/index.js";
export default {
  name: "",
  props: ["personInfo", "keywords"],
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      liist: "",
      tuilist: "",
      tuin: "",
      jianshow: true,
      show: true,
      list: [
        // {
        //   img: require("../../assets/image/zixun/1.png"),
        //   text: "书法美学的民族化和现代化",
        //   duration: "00:04:17",
        //   time: "2022-06-18 17:15",
        // },
        // {
        //   img: require("../../assets/image/zixun/2.png"),
        //   text: "中国第一历史档案馆新馆向社会开放",
        //   duration: "00:04:17",
        //   time: "2022-06-18 17:15",
        // },
        // {
        //   img: require("../../assets/image/zixun/3.png"),
        //   text: "中华文明发展的历史",
        //   duration: "00:04:17",
        //   time: "2022-06-18 17:15",
        // },
        // {
        //   img: require("../../assets/image/zixun/1.png"),
        //   text: "书法美学的民族化和现代化",
        //   duration: "00:04:17",
        //   time: "2022-06-18 17:15",
        // },
      ],
      swiperOption: {
        initialSlide: 0,
        loop: false,
        paginationClickable: true,
        slidesPerView: this.$store.state.smallPc ? 2 : 4,
        slidesPerGroup: this.$store.state.smallPc ? 2 : 4,
        spaceBetween: 20,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      },
      swiperOption_phone: {
        initialSlide: 0,
        loop: false,
        paginationClickable: true,
        EffectCards: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: ".rePhone-button-next",
          prevEl: ".rePhone-button-prev",
        },
      },
      equipment: this.$store.state.isEquipment,
    };
  },
  created() {
    console.log('kkkkkkey', this.keywords);
    this.getList();
  },

  methods: {
    getList() {
      // 推荐视频
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          fq: [
            {
              key: "KEYWORDS",
              value: [this.keywords], //名词，动词
              operator: "lk",
            },
          ],
        })
        .then((res) => {
          this.liist = res.result;
          if (res.result.length == 0) {
            //推荐视频隐藏
            this.show = false;
            this.$emit("child-thr", this.show);
          } else if (res.result.length <= 4) {
            this.jianshow = false;
          }
        });
    },
    shipx(n) {
      //详情跳转
      let routeUrl = this.$router.resolve({
        path: "/xq",
        query: {
          qing: n.id,
          tui: "pul_t",
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  watch: {
    keywords() {
      this.getList();
    }
  }
};
</script>

<style lang='scss' scoped>
.Recommend {
  position: relative;

  .card {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
    position: relative;
    cursor: pointer;

    .cover {
      width: 100%;
      height: 223px;
      display: block;
    }

    .duration {
      p {
        font-size: 1rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 3rem;
        margin-top: -3rem;
        text-align: right;
        padding-right: 1.25rem;
      }
    }

    .coverWrap {
      position: relative;

      .pause {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 4rem;
      }
    }

    .card_content {
      padding: 1.25rem 1.125rem 1rem 1.125rem;

      .title {
        font-size: 1.125rem;
        font-weight: 500;
        color: #212121;
        line-height: 1.6875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time {
        font-size: 1rem;
        font-weight: 400;
        color: #c29460;
        line-height: 3rem;
        height: 3rem;
      }
    }
  }

  .Recommend_pc {
    position: relative;

    .arrows {
      position: absolute;
      top: 50%;
      width: 100%;

      img {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }

      .rePC-button-prev {
        margin-left: -3rem;
      }

      .rePC-button-next {
        margin-right: -3rem;
        float: right;
      }
    }
  }

  .Recommend_phone {
    padding-bottom: 2rem;

    .card .coverWrap .pause {
      width: 6rem;
    }

    .arrows {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;

      img {
        width: 2rem;
        height: 2rem;
        margin: 0 0.625rem;
      }

      // .swiper-button-prev {
      //   left: -4rem;
      // }

      // .swiper-button-next {
      //   right: 3.5rem;
      // }
    }
  }

  @media screen and(max-width:1440px) {
    .card .cover {
      height: 151px;
    }
  }
}
</style>